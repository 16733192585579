import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Container} from "@mui/material";
import Header from "./Header";
import NewsLetterPopup from "./NewsLetterPopup";

function BlogCost() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Weblap frissítés</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">
                            Mennyibe kerül 2024-ben egy weblap készítése Magyarországon?
                        </h1>
                    </header>
                    <div className="post-headline">
                        <h2 className="post-headline-title">
                            Gyakran találkozni azzal a kérdéssel, hogy mennyiből lehet elkészíteni egy honlapot.
                            Nem könnyű egy konkrét számot meghatározni, de sorra tudjuk venni, hogy milyen
                            tényezőket vegyél figyelembe, mikor szakembert választasz.
                        </h2>
                    </div>
                    <div className="post-image center">
                        <img src="/blog_cost.jpg" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <p>
                            <span className="bold">Ugyanúgy, ahogyan autóból sem egy típus kering az
                                utakon, úgy honlapból is sokféle van. </span >
                            Ahogyan az autóknál is, így itt is fontos látni, hogy nem mindegy, mi a rövid és a
                            hosszú távú célod, mire szeretnéd használni, milyen feladatokat kell ellátnia.
                            Elég egy kicsi két személyes jármű, családi autó kell, vagy egy kamion?
                            Gyorsan tudjon menni, vagy sok rakomány férjen el rajta, legyen megbízható és
                            biztonságos, vagy csak csajozni akarsz vele?
                        </p>
                        <p>
                            Egyszerre legyen olcsó, de kinézetre csillogó? Csak a külső számít,
                            és az nem baj, ha az első emelkedőn lelassulsz, éles kanyarban pedig kicsúszol
                            vele és az életedet is kockáztatod?
                        </p>
                        <br/>
                        <p>
                            Az első, amit rögtön az <span className="bold">elején tisztázni kell, hogy sablonra vagy egyedi honlapra,
                            webshopra van szükséged</span >. A sablon és az egyedi előnyeiről és hátrányairól, illetve arról,
                            hogy kinek melyiket ajánljuk, <a href="/ingyenes_weblap.html" className="hero1">ebben a blogbejegyzésben</a> olvashatsz bővebben.
                        </p>
                        <p>
                            <span className="bold">Sablonból</span > webdesignerek / weblapépítők 150-200 ezer Ft-ból készítenek már olyan oldalakat,
                            amik külsőre csillogóak. Ennél mélyebben a zsebedbe kell nyúlni (kb. plusz 100 ezer Ft),
                            ha szeretnéd, hogy az oldal technikai része, a különböző összekötések is rendben legyenek,
                            hiszen ezek garantálják, hogy organikusan hosszabb távon is jól működjön az oldalad.
                        </p>
                        <p>
                            <span className="bold">Egyedi weboldalak</span > fejlesztése 400 ezer Ft felett kezdődik.
                        </p>
                        <p>
                            Ezeket mondhatjuk alap áraknak, ehhez jönnek hozzá az extrák.
                        </p>
                        <p>
                            Kérdés, hogy meglévő marketing anyagaid részben, vagy egészben felhasználhatóak-e, rendelkezel-e
                            tartalom- és kulcsszókutatás eredményeivel, amik beépíthetőek a honlapodba. Vagyis tovább <span className="bold">növeli
                            az árakat</span >, ha szövegírást kérsz, ha nincsenek képeid, vagy ugyan vannak, de nem a megfelelő
                            formában/méretben tudod átadni a fejlesztőnek.
                            Az árat befolyásolja, hogy hány aloldallal rendelkezzen a weblap, illetve milyen funkciókat tudjon.
                        </p>
                        <p>
                            További <span className="bold">költségnövelő</span >, ha minél több meglévő vagy jövőbeni rendszereiddel kell össze kötni a
                            weboldalt (rendelés, raktár, beszerzés, workflow stb. ). Nem mindegy mennyi és
                            milyen automatizmusokat, kimutatásokat szeretnél beépíttetni.
                        </p>
                        <p>
                            Amennyiben bizonytalan vagy, hogy mire van szükséged, hogy a te vállalkozásodnak elég-e egy
                            sablonból webdesignolt oldal, vagy webfejlesztő által készített egyedi honlapra van szükséged,
                            akkor mindenképpen konzultálj szakemberrel. Kezdő vállalkozóként, lehet évekig megfelel neked
                            még a sablon. Persze az egyedileg készült honlap se garancia, ha nem egy hozzáértő szakember
                            készíti el. Pár hónapos tanfolyam elvégzése még nem jelenti azt, hogy tud a programozó mindent.
                            Erre a szakmára is igaz, hogy az évek és a rutin… és a folyamatos tanulás, hiszen hihetetlenül
                            gyorsan fejlődik az informatika.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="#contact" className="hero1">Konzultációt kérek.</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <span className="bold">Ahhoz, hogy magadtól el tudd dönteni, hogy árajánlat alacsony vagy magas, a fenti feladatok
                                mellett vedd figyelembe a webdesigner, vagy webfejlesztő munkaidő szükségletét.</span >
                        </p>
                        <p>
                            Mondjuk legyen ez egy egyszerű, 3 aloldallal rendelkező honlap. Tegyük fel, hogy 1 órás
                            egyeztetésen pontosan definiálni tudjátok, a honlap kinézetét, szerkezetét, külső és belső
                            kapcsolatait, majd másnap Te minden szükséges szöveget, képet a megfelelő minőségben át
                            tudod adni. Majd egy héten belül megkapod a kész honlapot, ami elsőre remek, nem kell rajta
                            semmit sem módosítani, nem kell utóegyeztetés, ismételt ellenőrzés stb. És még betanítást se
                            fogsz kérni, hiszen első ránézésre mindent perfekten tudsz használni.
                        </p>
                        <p>
                            <span className="bold">Ez álomszerűnek hangzik? Igen! Nem csak annak hangzik, hanem ez az is…</span >
                        </p>
                        <br/>
                        <p>
                            <span className="bold">A valóság általában az</span >, hogy több körös egyeztetésre van szükség már a feladat elején, és a
                            fejlesztés során folyamatosan, hogy biztosan az általad megálmodott oldal jöjjön létre.
                            Hogy mindig van olyan szöveg, kép, funkció, ami nem stimmel és alakítgatni kell rajta.
                            Hogy több körben szükséges, hogy teszteljed az oldalt.
                            … és még így is lesz olyan, amiről napok, vagy hónapok múlva a használat során kiderül,
                            hogy módosítani szükséges.
                        </p>
                        <p>
                            <span className="bold">A legelső és legfontosabb lépés a tervezés </span >
                            (vagy legalábbis ennek kellene lennie). Ez akár
                            hetekig, bonyolult esetekben hónapokig eltarthat. A tervezés során meg kell határoznod, hogy
                            mi a célod a honlappal. Ki a célközönség? Mit fognak a látogatók az oldalon csinálni? Te, a
                            megrendelő mit fogsz csinálni? Milyen folyamatok, milyen határidőkkel történnek? Stb. És kell
                            egy jövőkép is: azt is látni kell az optimális tárhely, és honlap tervezéshez, hogy mik a
                            jövőbeni célok. Pl. egy-két éven belül milyen vásárlói növekedésre számítasz, milyen új termékeid,
                            szolgáltatásaid lehetnek, amikhez majd új honlap kell, vagy a meglévőn kell majd új részeket,
                            funkciókat kialakítani.
                        </p>
                        <p>
                            És csak ezután jöhet, hogy hogyan is nézzen ki az oldal. Milyen struktúra, funkciók, design...
                        </p>
                        <p>
                            Ha mindez megvan, akkor kezdődik csak az oldal vázának elkészítése és a programozása…
                        </p>
                        <p>
                            A fentiek alapján szerinted mennyit foglalkozik a Te honlapod egyediségével, a Te külön
                            kéréseiddel az, aki 100 ezer Ft-ért készít el egy honlapot? (Számoljunk az egyszerűség kedvéért
                            a KSH 2024 májusi bruttó átlagkereset alapján 4 ezer Ft/órával, ami azt jelenti, hogy 100 ezer Ft
                            az kb. 25 óra, nagyjából 3 nap…)
                        </p>
                        <p>
                            Vajon a csillogó máz alatt minden technikai beállítás rendben van? Hosszú távon fogod tudni
                            használni, szükség esetén bővítetni, továbbfejlesztetni lehetséges? Vagy pár hónap, vagy év
                            és dobhatod is ki a kukába?
                        </p>
                        <div style={{color: "#54abc9"}}>
                            <p>
                                Beszéljük át együtt, mire van szükséged.
                            </p>
                        </div>
                    </div>
                </article>
            </Container>
            <div id="contact"  />
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogCost