import React, {useEffect, useState} from "react";
import {Container, Typography} from "@mui/material";

type CardProp = {
    text: string,
    name: string
}

type CardSliderProps = {
    cards: CardProp[];
};

const CardSlider: React.FC<CardSliderProps> = ({ cards }) => {
    const [visibleCards, setVisibleCards] = useState<number>(3); // Alapértelmezett 3 (asztali nézet)
    const [startIndex, setStartIndex] = useState<number>(0);

    // Ablakméret figyelése
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVisibleCards(1); // Mobil nézet
            } else {
                setVisibleCards(3); // Asztali nézet
            }
        };

        handleResize(); // Inicializálás
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Lapozás jobbra
    const handleNext = () => {
        setStartIndex((prev) =>
            Math.min(prev + 1, cards.length - visibleCards)
        );
    };

    // Lapozás balra
    const handlePrev = () => {
        setStartIndex((prev) => Math.max(prev - 1, 0));
    };

    return (
        <div className="card-slider">
            <button onClick={handlePrev} disabled={startIndex === 0}>❮</button>

            <div className="cards-container">
                {cards.slice(startIndex, startIndex + visibleCards).map((card, index) => (
                    <div className="card" key={index} style={{backgroundImage: "url('/right-quotation-mark.webp')", backgroundRepeat: 'no-repeat', lineHeight: '160%'}}>
                        <p>{card.text}</p>
                        <h4>{card.name}</h4>
                    </div>
                ))}
            </div>

            <button
                onClick={handleNext}
                disabled={startIndex + visibleCards >= cards.length}
            >
                ❯
            </button>
        </div>
    );
};


function Review() {
    const cards = [
        {text: '"Alapos munkát végeztek, minden kérdésemnek, kérésemnek utánajártak"', name: 'János'},
        {text: '"Mi folyamatos karbantartási szerződést kötöttünk, mivel rendszeresen merülnek fel újabb és újabb fejlesztési igények a kollégáink és a vevőink részéről is."', name: 'Judit'},
        {text: '"Akadozott a weboldalunk és ezért kértünk honlap auditot. Gondosan átvizsgálták az oldalunkat és közérthetően elmagyarázták a problémákat, és megoldották azokat."', name: 'Zita'},
        /*{text: '"Egyedi szoftvert kértünk a munkafolyamatunkra és maradéktalanul elkészült"', name: 'Kiss Béla'},
        {text: '"Ez még plusz egy vélemény"', name: 'Lajos'}*/
    ];

    return(
        <>
            <Container  className="hero-container">
                <Typography component="h2"
                            variant={"h2"}
                            sx={{color: '#54abc9',  margin: '30px'}}
                >
                    Vélemények
                </Typography>
                <CardSlider cards={cards} />
            </Container>
        </>
    )
}

export default Review