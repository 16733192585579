import {Container, Grid, Box, Typography} from "@mui/material";
import {motion, useAnimation, Variants} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import React, {ReactNode} from "react";


function BlogItem() {

    const divVariants: Variants = {
        hidden: { x: '100%', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    };

    interface DivSectionProps {
        children: ReactNode;
    }

    const DivSection: React.FC<DivSectionProps> = ({ children }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1
        });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <Grid item xs={12} sm={6}>
                <Box
                    component="div" //component={motion.div}
                    ref={ref}
                    //initial="hidden"
                    //animate={controls}
                    //variants={divVariants}
                    className="blog-item"
                    //sx={{margin: '20px'}}
                 >
                    {children}
                </Box>
            </Grid>
        );
    };
    return(
        <>
            <div className="">
                <Container id="blog" className="blog" sx={{width: '100vw'}}>
                    <Typography component="h2"
                                variant={"h2"}
                                sx={{color: '#54abc9',  margin: '30px'}}
                    >
                        Legnépszerűbb blogcikkek
                    </Typography>
                    <Grid container marginY={2}>
                        <DivSection>
                            <a href="/blog/2025-webdesign-trendjei-1.html" style={{textDecoration: 'none'}}>
                                <h3 className="hero1" style={{minHeight: '76px'}}>
                                    2025 Webdesign Trendjei 1. – 5+1. Szín és stílus divat a webdesignban
                                </h3>
                                <Box component="img" src="/pantone_n.JPG" width="70%" className="logo" alt="webdesign trendek" sx={{marginY: '20px'}}/>
                                <p style={{lineHeight: '160%'}}>
                                    A webdesign világa folyamatosan fejlődik, és ez alól 2025 sem kivétel. Ez a blogcikk egy
                                    cikksorozat első része, amelyben a 2025-ben divatos színeket és a stílusokat vizsgáljuk meg.
                                </p>
                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                            </a>
                        </DivSection>
                        <DivSection>
                            <a href="/blog/mobil-barat.html" style={{textDecoration: 'none'}}>
                                <h3 className="hero1" style={{minHeight: '76px'}}>
                                    Mi a mobilbarát weboldalak két kulcsfontosságú eleme?
                                </h3>
                                <Box component="img" src="/smartphone.webp" width="70%" className="logo" alt="mobil barat" sx={{marginY: '20px'}}/>
                                <p style={{lineHeight: '160%'}}>
                                    Manapság már a webes forgalom fele mobilon zajlik, sőt nem csak a magánéletben
                                    használjuk mobiljainkat, hanem az üzleti életben is egyre elterjedtebb. Ezeken
                                    kívül a találati rangsorolásokban is nagy jelentőséggel bír...
                                </p>
                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                            </a>
                        </DivSection>
                        <DivSection>
                            <a href="/blog/google-tevhitek.html" style={{textDecoration: 'none'}}>
                                <h3 className="hero1" style={{minHeight: '76px'}}>
                                    Google által cáfolt SEO tévhitek
                                </h3>
                                <Box component="img" src="/blog_google_tevhitek.webp" width="70%" className="logo" alt="google tévhitek" sx={{marginY: '20px'}}/>
                                <p style={{lineHeight: '160%'}}>
                                    Faggatjuk a Google-t .... jahh nem, magától is elárulja, hogyan működik.
                                    Weboldalaink keresőmotor optimalizálásánál (SEO) különös figyelmet kell fordítanunk a
                                    keresésben piacvezető Google tanácsaira.
                                </p>
                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                            </a>
                        </DivSection>
                        <DivSection>
                            <a href="/blog/seo-roviden-erthetoen.html" style={{textDecoration: 'none'}}>
                                <h3 className="hero1">
                                    SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés
                                </h3>
                                <Box component="img" src="/seo_boszi.webp" width="70%" className="logo" alt="seo boszi" sx={{marginY: '20px'}}/>
                                <p style={{lineHeight: '160%'}}>
                                    Lépten-nyomon sűrűn belebotlani a SEO kifejezésbe, az ingyenes SEO gyorstalpalókon keresztül a
                                    méreg drága, havi díjas SEO szolgáltatásokig. Az ember önkéntelenül is elcsodálkozik rajta, hogy
                                    akkor most ez ilyen egyszerű...
                                </p>
                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                            </a>
                        </DivSection>
                    </Grid>
                    <a href="/blog.html" style={{textDecoration: 'none'}}><p style={{color: '#54abc9'}}>További blogcikkek...</p></a>
                </Container>
            </div>
        </>
    )
}

export default BlogItem