import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ReactNode} from "react";
import Footer from "./Footer";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "./Header";
import LandingFooter from "./LandingFooter";

function LandingSeoAuditThanks() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Weboldal audit</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div className="page">
                <div className="content">
                    <Header/>
                    <Container sx={{marginY: "100px"}} className="blog-page">
                        <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                            Jól döntöttél!
                        </Typography>
                        <Container id="blog" className="blog">
                            <Stack direction="column" spacing={5}>
                                <p>
                                    A honlap audit checklista linkjét tartalmazó e-mail hamarosan megérkezik a megadott e-mail címre.
                                </p>
                                <p>
                                    Ha nem találnád a beérkezett levelek közt, nézd meg a promóciók és a spam mappát, ha itt sem találod, akkor írj az <a href="mailto:info@netbrain.hu">info@netbrain.hu</a> e-mail címre és elküldjük Neked.
                                </p>
                                <p>
                                    Köszönjük:
                                <br/>
                                    Netbrain csapata
                                </p>
                                <p>
                                    Amíg megérkezik a levél ...
                                </p>
                                <p>
                                    Legutóbbi blogbejegyzéseink:
                                    <ul>
                                        <li>
                                            <a href="/blog/seo-roviden-erthetoen.html">SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés</a>
                                        </li>
                                        <li>
                                            <a href="/blog/landing_vagy_weboldal.html">Landing oldalra vagy weboldalra van szükséged?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/mennyibe_kerul_egy_weblap.html">Mennyibe kerül 2024-ben egy weblap Magyarországon?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/weblap_frissites.html">Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését válaszd?</a>
                                        </li>
                                    </ul>
                                </p>
                            </Stack>
                        </Container>
                    </Container>
                </div>
            <LandingFooter/>
            </div>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
        </>
    )
}

export default LandingSeoAuditThanks