import {Button, Container, Stack, TextField} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DayPicker} from 'react-day-picker';
import classNames from "react-day-picker/style.module.css";
import { hu } from "date-fns/locale";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {motion, useScroll} from "framer-motion";
import Header from "./Header";
import LandingFooter from "./LandingFooter";

const Appointment = () => {
    const [availableDates, setAvailableDates] = useState([])
    const [availableTimes, setAvailableTimes] = useState([])
    const [times, setTimes] = useState<string[] | null>([])
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>(null)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);
    type Schedule = { date: string; times: string[] };
    const navigate = useNavigate();
    const { scrollYProgress } = useScroll();

    useEffect(() => {
        // Fetch hívás csak az oldal betöltésekor fut le
        fetch( "https://lq2h24cppn3ptp4l36a7lo5p4y0wuigl.lambda-url.eu-west-1.on.aws/", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setAvailableTimes(data);
                setAvailableDates(data.map((item: Schedule) => item.date));
            })
            .catch((error) => {
                console.error("Hiba a küldés során. Próbálja meg később!");
            });
    }, []);

    useEffect(() => {
        if(selectedDate != null){
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Hónapok 0-tól indulnak
            const day = String(selectedDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            const x = filterTimesByDate(availableTimes, formattedDate);
            setTimes(x);
            setSelectedTime(null);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (availableDates.length > 0 && !selectedDate) {
            // Az első elérhető dátumot állítjuk be
            const initialDate = new Date(availableDates[0]);
            setSelectedDate(initialDate);
        }
    }, [availableDates]);

    // 1. Függvény: Kiszedi a date mezőket
    function extractDates(scheduleList: Schedule[]): string[] {
        return scheduleList.map(item => item.date);
    }

    // 2. Függvény: Szűr a megadott date-re, és visszaadja a time-listát
    function filterTimesByDate(scheduleList: Schedule[], targetDate: string): string[] {
        const foundItem = scheduleList.find(item => item.date === targetDate);
        return foundItem ? foundItem.times : [];
    }

    function getDisabledDates(dateStrings: string[]): Date[] {
        // Átalakítjuk a stringeket Date típusra
        const dates = dateStrings.map(dateStr => new Date(dateStr));

        const missingDates: Date[] = [];

        // Kezdő dátum (első elem) és záró dátum (utolsó elem)
        const startDate = dates[0];
        const endDate = dates[dates.length - 1];

        // Végigmegyünk a kezdő és záró dátumok közötti napokon
        let currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1); // Kezdjük az első utáni nappal

        while (currentDate < endDate) {
            // Ha az aktuális dátum nincs a bemeneti listában, hozzáadjuk a hiányzó dátumokhoz
            if (!dates.some(date => date.getTime() === currentDate.getTime())) {
                missingDates.push(new Date(currentDate)); // Új példányt adunk hozzá
            }

            // Lépünk a következő napra
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return missingDates;
    }

    const timeClick = (time: string) => {
        setSelectedTime(time)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            email: value,
        });
        // Egyszerű reguláris kifejezés az email validációhoz
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value && !emailRegex.test(value)) {
            setEmailError("Nem megfelelő e-mail formátum.");
        } else {
            setEmailError(null);
        }
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            phone: value,
        });
        // Egyszerű reguláris kifejezés az email validációhoz
        const phoneRegex = /^[\d\s()+-]+$/;
        if (value && !phoneRegex.test(value)) {
            setPhoneError("Nem megfelelő a telefonszám formátum.");
        } else {
            setPhoneError(null);
        }
    };

    const validateFormData = () => {
        if(!selectedTime || !formData.name || !formData.email || !formData.phone || !formData.message || emailError || phoneError)
            return true
        else return false
    }

    const book = () => {
        if(selectedDate != null && selectedTime != null){
            const year = selectedDate.getFullYear();
            const month = String(selectedDate?.getMonth() + 1).padStart(2, '0'); // Hónapok 0-tól indulnak
            const day = String(selectedDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            const postData = {
                date: formattedDate,
                time: selectedTime,
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                message: formData.message
            };
            console.log('Booking data:', postData);

            fetch('https://g7htf4df5sjptfniahrepgsnoi0ehnte.lambda-url.eu-west-1.on.aws', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData),
            })
                .then((response) => {
                    if (!response.ok) {
                        // Ha a státuszkód nem 2xx, hiba kezelés
                        return response.json().then((errorData) => {
                            // A szerver által visszaküldött hibaüzenet megjelenítése
                            throw new Error(errorData.message || `Hiba történt: ${response.status}`);
                        });
                    }
                    return response.json();
                })
                .then((data) => {
                    navigate('/idopont-foglalas-thanks.html');
                })
                .catch((error) => {
                    alert("Hiba a küldés során. Próbálja meg később!")
                });
        }

    }

    return(
        <>
            <Helmet>
                <title>Netbrain - időpont foglalás</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <div className="page">
                <div className="content">
                    <Container className="blog-page" sx={{padding: {xs: '10px'}}}>
                        <form action="https://g7htf4df5sjptfniahrepgsnoi0ehnte.lambda-url.eu-west-1.on.aws" onSubmit={book}
                              method="POST">
                        <Stack direction="column" spacing={4} alignItems="center">
                        <h1>Időpont foglalás</h1>
                            <h2>Online megbeszélés (Google Meet)</h2>
                            <div>
                                <p>
                                    Itt foglalhatsz időpontot amennyiben <span className="bold">új honlapot szeretnél.</span>
                                </p>
                                <p>
                                    Illetve itt foglalhatsz időpontot akkor is, ha <span className="bold">meglévő weboldaladat szeretnéd továbbfejleszteni, megjavítani.</span>
                                </p>
                                <p>Nem működik? Szétesik? Csúnya?
                                    Elavulttá vált a honlapod?</p>

                                <p>Weboldalad képességei már nem elégségesek?</p>

                                <p>Látod, hogy valami nem megfelelő a honlapodon, de bizonytalan vagy a megoldásban?</p>


                                <h4>Hamarosan megérkezik a segítség!</h4>



                                <p>Töltsd ki a lenti időpontfoglalót.</p>

                                <p>Kérjük az üzenet rovatban pár mondatban írd le a problémádat, és add meg honlapod elérhetőségét is.</p>
                            </div>

                                <Stack direction={{xs: "column", md: 'row'}} justifyContent="center" spacing={3}>
                                    <div><DayPicker
                                        showWeekNumber={false}
                                        classNames={classNames}
                                        locale={hu}
                                        mode="single"
                                        disabled={[{ before: new Date(availableDates[0]) }, { after: new Date(availableDates[availableDates.length-1]) }, ...getDisabledDates(availableDates)]}
                                        selected={selectedDate ?? new Date(availableDates[0])}
                                        startMonth={new Date(availableDates[0])} endMonth={new Date(availableDates[availableDates.length-1])}
                                        onSelect={(date) => setSelectedDate(date ?? null)}
                                    />
                                    </div>
                                    <div>
                                        <Stack direction={{xs: "row", md: 'column'}} spacing={2}>
                                            {
                                                times?.map(((time, i) => {
                                                        console.log("Time: ", time);
                                                        // Return the element. Also pass key
                                                    const btnVariant = time === selectedTime ? "contained" : "outlined";
                                                    return (<Button variant={btnVariant} className="time-btn" onClick={() => timeClick(time)}>{time}</Button> )
                                                    })
                                                )
                                            }
                                        </Stack>
                                    </div>
                                </Stack>
                            { selectedDate && selectedTime &&
                                <div>A választott időpont: {selectedDate?.toLocaleDateString()} {selectedTime}</div>
                            }
                        <TextField id="name"
                                   name="name"
                                   type="text"
                                   value={formData.name}
                                   onChange={handleChange}
                                   label="Keresztnév"
                                   variant="filled"
                                   InputLabelProps={{ style: { color: "black" } }}
                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black', maxWidth: '500px'}}
                                   required
                                   fullWidth
                        />
                        <TextField id="email"
                                   name="email"
                                   value={formData.email}
                                   onChange={handleEmailChange}
                                   type="email"
                                   label="E-mail"
                                   variant="filled"
                                   error={!!emailError}
                                   helperText={emailError}
                                   InputLabelProps={{ style: { color: "black" } }}
                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9', maxWidth: '500px'}}
                                   required
                                   fullWidth
                        />
                            <TextField id="phone"
                                       name="phone"
                                       value={formData.phone}
                                       onChange={handlePhoneChange}
                                       type="text"
                                       label="Telefon"
                                       variant="filled"
                                       error={!!phoneError}
                                       helperText={phoneError}
                                       InputLabelProps={{ style: { color: "black" } }}
                                       sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9', maxWidth: '500px'}}
                                       required
                                       fullWidth
                            />
                            <TextField id="message"
                                       name="message"
                                       value={formData.message}
                                       onChange={handleChange}
                                       type="text"
                                       label="Üzenet"
                                       variant="filled"
                                       multiline
                                       rows={4}
                                       InputLabelProps={{ style: { color: "black" } }}
                                       sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9', maxWidth: '500px'}}
                                       required
                                       fullWidth
                            />
                                {
                                    <Button disabled={validateFormData()} onClick={() => book()} color="error" className="book-btn" variant="contained">Időpontot foglalok</Button>
                                }

                        </Stack>
                    </form>
                    </Container>
                </div>
                <LandingFooter/>
            </div>
        </>
    )
}

export default Appointment