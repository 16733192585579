import {Box, Button, Checkbox, Dialog, DialogTitle, DialogContent, Container, TextField, Stack, Typography, FormControlLabel} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {color} from "framer-motion";
import {ChangeEvent, FormEvent, useState} from "react";

function Footer() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Form adatainak JSON formátumba alakítása
        const jsonData = JSON.stringify(formData);

        // Adatok elküldése a szerverre
        fetch('https://4d3ylnmxsdkcauiwnar2dfsqhu0eyjwg.lambda-url.eu-west-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setOpen(true)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const label = (
        <Box component="span" sx={{color: "white"}}>Elfogadom az&nbsp;
            <a style={{color: 'white'}}
                href="/adatvedelmi.pdf"
                target="_blank"
                //onClick={onLinkClick}
            >
      adatkezelési tájékoztatóban
    </a>
            &nbsp;foglaltakat!
  </Box>
    )


    return(
        <>
            <Container id="kapcsolat" maxWidth={false} sx={{backgroundColor: '#54abc9', paddingY: "30px"}}>
                <Container className="container">
                    <Box component="div" justifyContent="center" alignContent="center">
                        <Typography variant="h4" color="white">Lépj kapcsolatba velünk!</Typography>
                        <Typography variant="h6" color="white" gutterBottom>Ahhoz, hogy hatékonyan
                            segíthessünk, kérjük minél részletesebben töltsd ki űrlapunkat. Az első konzultáció ingyenes.</Typography>
                        <div className="newsletter-form  mx-auto newsletter--l1-2">
                            <form action="https://4d3ylnmxsdkcauiwnar2dfsqhu0eyjwg.lambda-url.eu-west-1.on.aws/" onSubmit={handleSubmit}
                                  method="POST">
                                <Stack direction="column" spacing={5} className="row">
                                    <TextField id="name"
                                               name="name"
                                               type="text"
                                               value={formData.name}
                                               onChange={handleChange}
                                               label="Név"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black'}}
                                               required />
                                    <TextField id="email"
                                               name="email"
                                               value={formData.email}
                                               onChange={handleChange}
                                               type="email"
                                               label="E-mail"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                               required />
                                    <TextField id="phone"
                                               name="phone"
                                               value={formData.phone}
                                               onChange={handleChange}
                                               type="text"
                                               label="Telefonszám"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                               required />
                                    <TextField
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        variant="filled"
                                        required
                                        label="Üzenet"
                                        multiline
                                        rows={4}
                                        InputLabelProps={{ style: { color: "black" } }}
                                        sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                    />
                                    <FormControlLabel required classes={{ asterisk: 'Mui-required' }} control={<Checkbox style ={{
                                        color: "white",
                                    }} />} label={label} />
                                    <div className="col-lg-12 mt-2">
                                        <button type="submit" className="message-button cta">
                                            Üzenet küldése
                                        </button>
                                    </div>
                                </Stack>
                            </form>
                        </div>


                    </Box>
                </Container>
            </Container>
            <Container maxWidth={false} sx={{backgroundColor: '#54abc9', paddingY: "10px"}}>
                <Container className="container">
                    <div className="footer-area footer-area--l1">
                        <Stack direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
                            <Box component="div">
                                <Typography variant="h6" color="white" gutterBottom>
                                    Célunk az, hogy büszke légy a honlapodra!
                                </Typography>

                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white" >További linkek</Typography>
                                <ul className="footer-widgets__list">
                                    <li><a href="/adatvedelmi.pdf" target="_blank">Adatkezelési
                                        Tájékoztató</a></li>
                                </ul>
                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white">Kapcsolat</Typography>

                                <ul className="footer-widgets__list footer-widgets--address">
                                    <li>
                                        <Stack direction="row" spacing={2} justifyContent="flex-start">
                                            <a href="https://facebook.com/netbrain.hu" target="_blank" aria-label="Kövess Facebookon"><FacebookIcon aria-label="Facebook icon" fontSize="large" sx={{color: 'white'}}/></a>
                                            <a href="https://instagram.com/netbrain_hu" target="_blank" aria-label="Kövess Instagramon"><InstagramIcon aria-label="Instagram icon" fontSize="large" sx={{color: 'white'}}/></a>
                                        </Stack>
                                    </li>
                                    <li>
                                        <LocationOnIcon sx={{paddingRight: '0.5rem'}}/>
                                        <Typography component="p" variant="body1">1101 Budapest, Kőbányai út 43/B</Typography>
                                    </li>
                                    <li>
                                        <PhoneIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="tel:36202576845"><Typography component="p" variant="body1">+36 20 257 6845</Typography></a>
                                    </li>
                                    <li>
                                        <MailOutlineIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="mailto:info@netbrain.hu"><Typography component="p" variant="body1">info@netbrain.hu</Typography></a>
                                    </li>
                                </ul>

                            </Box>
                        </Stack>
                    </div>
                    <div className="footer-copyright footer-copyright--l1">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7">
                                <div className="copyright text-center text-md-start">
                                    <Typography variant="body1" color="white" className="copyright__text">Copyrights © 2024 NetBrain</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stack flexDirection="row">
                        <Box
                            component="img"
                            alt="logo"
                            src="/logo.jpg"
                            sx={{ marginRight: '1em', height: 40 }}
                        />
                        <Typography variant="h4" gutterBottom sx={{ marginX: 'auto', transform: 'translateX(-100px)' }}>Ürlap elküldése sikeres</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" gutterBottom>Kedves {formData.name}!</Typography>
                    <Typography variant="body1" gutterBottom>Köszönjük, hogy kitöltötted az űrlapot. Hamarosan felvesszük veled a kapcsolatot.</Typography>
                    <Typography variant="body1">Üdvözlettel,</Typography>
                    <Typography variant="body1"gutterBottom>Netbrain</Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Footer