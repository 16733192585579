import React, {useEffect, useState} from "react";
import {Container, Typography} from "@mui/material";


type CardProp = {
    url: string,
    alt: string,
    text: string,
    desc: string
}

type CardSliderProps = {
    cards: CardProp[];
};

const CardSlider: React.FC<CardSliderProps> = ({ cards }) => {
    const [visibleCards, setVisibleCards] = useState<number>(3); // Alapértelmezett 3 (asztali nézet)
    const [startIndex, setStartIndex] = useState<number>(0);

    // Ablakméret figyelése
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVisibleCards(1); // Mobil nézet
            } else {
                setVisibleCards(1); // Asztali nézet
            }
        };

        handleResize(); // Inicializálás
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Lapozás jobbra
    const handleNext = () => {
        setStartIndex((prev) =>
            Math.min(prev + 1, cards.length - visibleCards)
        );
    };

    // Lapozás balra
    const handlePrev = () => {
        setStartIndex((prev) => Math.max(prev - 1, 0));
    };

    return (
        <div className="card-slider">
            <button onClick={handlePrev} disabled={startIndex === 0}>❮</button>

            <div className="cards-container">
                {cards.slice(startIndex, startIndex + visibleCards).map((card, index) => (
                    <div className="card-img" key={index}>
                        <div style={{background: '#eef7fa', padding: '10px', lineHeight: '160%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}}>
                            <h3>
                                {card.text}
                            </h3>
                            <p>
                                {card.desc}
                            </p>
                        </div>
                        <div style={{height: '600px', width: '100%', overflowY: 'scroll'}}>
                            <img src={card.url} alt={card.alt} width='100%' />
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={handleNext}
                disabled={startIndex + visibleCards >= cards.length}
            >
                ❯
            </button>
        </div>
    );
};


function Reference() {
    const cards = [
        {url: '/glassmax-screenshot.webp', alt: 'Glassmax weboldal', text: 'Glassmax Kft.', desc: 'Az egyedi reszponzív honlapon található az ajánlatkérő, amely összeköttetésben áll a belső vállalatirányítási rendszerrel (időpontfoglalás, munkafolyamat irányítás, partner és raktrkezelés, számlázás stb.) '},
        {url: '/bside-screenshot.webp', alt: 'BSide utazasi weboldal', text: 'BSide Travellers', desc: 'Egyedi utazási blog oldal, amely támogatja a gyakori tartalomelhelyezést, és lehetőséget ad a kapcsolatok és pénzkeresési lehetőségek kiépítésére. Modulárisan bővíthető funkciókkal: pl. feliratkozás, ajánlatkérés, partnerkezelés, webshop.'},
        {url: '/gmax-erp-screenshot.webp', alt: 'Glassmax ERP', text: 'Glassmax ERP', desc: 'A vállalat irányítási rendszer kiszolgálja az egyedi honlapon beérkező javítási és csere kéréseket, az exkluzív nagykereskedelmi felületet és a belső folyamatok irányítását. Folyamatosan bővíthető moduláris, adott vállalkozás típúsának megfelelő funkciók, többek között: partner nyílvántartó, termék kezelés, telephely és raktár kezelés, törzsvásárló rendszer, értékesítés, számlázás, webshop kezelés, munkafolyamat nyilvántartás.'},
        {url: '/mancs-screenshot.webp', alt: 'Mancs Akadémia', text: 'Mancs Akadémia', desc: 'Egy kutyasuli honlap tervezete, amely tükrözi a tulajdonos kutyák iránti szeretetét és tiszteletét. A honlapon a kutyák gazdáik minden szükséges informáiót információt megtalálnak.'},
        {url: '/blank_wireframe.webp', alt: 'Te oldalad', text: 'Legyen itt a Te oldalad is', desc: 'Elkészítjük Neked a honlapot amire nem csak büszke leszel, hanem eredményt is hoz.'}
    ];


    return(
        <>
            <Container className="hero-container">
                <Typography component="h2"
                            variant={"h2"}
                            sx={{color: '#54abc9',  margin: '30px'}}
                >
                    Referenciák
                </Typography>
                <CardSlider cards={cards} />
            </Container></>
    )
}

export default Reference;