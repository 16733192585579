import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ReactNode} from "react";
import Footer from "./Footer";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "./Header";

function Blog() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Rólunk</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container sx={{marginY: "100px"}} className="blog-page">
                <h1 className="hero1" style={{fontWeight: 'bold'}}>
                    Blog
                </h1>
                <Container id="blog" className="blog">
                    <Stack direction="column" spacing={5}>
                        <a href="/blog/2025-webdesign-trendjei-1.html" style={{backgroundColor: '#eef7fa', padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/pantone_n.JPG" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        2025 Webdesign Trendjei 1. – 5+1. Szín és stílus divat a webdesignban: A modern eleganciától, retrón át a fenntartható megoldásokig
                                    </h3>
                                    <p>
                                        Az idei évben a vizuális trendek között megtalálhatók a letisztult szürkeárnyalatos
                                        paletták, a nosztalgikus retro dizájn, valamint a cyberpunk esztétika futurisztikus elemei.
                                        Emellett a felhasználói élmény középpontjába kerül a világos és sötét mód közötti váltás,
                                        valamint a fenntartható dizájnmegoldások, amelyek hozzájárulnak az energiahatékony
                                        weboldalak létrehozásához
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/weboldal-gyorsteszt.html" style={{padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/blog_weblap_gyorsteszt.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        Teszteld le honlapod működését 10 perc alatt saját magad
                                    </h3>
                                    <p>
                                        Ha a honlapod lassú, nehézkes, vagy nem nyújt megfelelő felhasználói élményt, az a
                                        potenciális ügyfelek elvesztéséhez vezethet. Egy egyszerű önellenőrzéssel gyorsan
                                        felfedezheted, ha az oldalad nem felel meg az alapvető elvárásoknak, például nehézkesen
                                        navigálható, hibásak a linkek, vagy nem olvasható a tartalom.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/mikulas-seo-titok.html" style={{backgroundColor: '#eef7fa', padding: '25px', borderRadius: '45px', textDecoration: 'none', backgroundImage: "url('snowflake-white.png')" , backgroundRepeat: 'no-repeat', backgroundPositionX: 'right', backgroundPositionY: 'bottom'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/santa_seo.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        A Mikulás SEO titkai: Hogyan találja meg a legjobb ajándékokat?
                                    </h3>
                                    <p>
                                        Ha alaposan megfigyeljük, a Mikulás valójában a legnagyobb SEO szakértő, aki a
                                        legújabb technológiai eszközöket használja, hogy az év minden napján nyomon
                                        kövesse a gyerekek kívánságait és igényeit. De hogyan kapcsolódik mindez a
                                        keresőoptimalizáláshoz (SEO)?
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/mobil-barat.html" style={{padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/smartphone.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        A mobilbarát weboldalak két kulcsfontosságú eleme: a betöltési sebesség és a reszponzivitás
                                    </h3>
                                    <p>
                                        Manapság már a webes forgalom fele mobilon zajlik, sőt nem csak a magánéletben
                                        használjuk mobiljainkat, hanem az üzleti életben is egyre elterjedtebb. Ezeken
                                        kívül a találati rangsorolásokban is nagy jelentőséggel bír a mobilos
                                        felhasználói élmény, így SEO (keresőmotor optimalizálás) szempontjából is
                                        kiemelt szempont, hogy honlapod mobilbarát legyen.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/google-tevhitek.html" style={{backgroundColor: '#eef7fa', padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/blog_google_tevhitek.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        Google által cáfolt SEO tévhitek
                                    </h3>
                                    <p>
                                        Faggatjuk a Google-t .... jahh nem, magától is elárulja, hogyan működik.
                                        Weboldalaink keresőmotor optimalizálásánál (SEO) különös figyelmet kell fordítanunk a
                                        keresésben piacvezető Google tanácsaira. Az alábbiakban olvashatod azokat a SEO tanácsokat,
                                        amelyek találataik szerint széles körben terjednek, de szerintük nem szabad rájuk fókuszálni.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/seo-roviden-erthetoen.html" style={{padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/seo_boszi.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés
                                    </h3>
                                    <p>
                                        Lépten-nyomon sűrűn belebotlani a SEO kifejezésbe, az ingyenes SEO gyorstalpalókon keresztül a
                                        méreg drága, havi díjas SEO szolgáltatásokig. Az ember önkéntelenül is elcsodálkozik rajta, hogy
                                        akkor most ez ilyen egyszerű, ingyen is megtanulható, vagy inkább mágikus tudomány, amire
                                        specialistát kell alkalmazni. Lehet ez egyszerre mindkettő, vagy egyik sem? Az igazság - mint
                                        szinte mindig – valahol félúton van.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/landing-vagy-weboldal.html" style={{backgroundColor: '#eef7fa', padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <img src="/blog_landing_web.webp" className="logo mobile-half"/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        Landing oldalra vagy weboldalra van szükséged?
                                    </h3>
                                    <p>
                                        Ebben a blogban először is tisztába tesszük, hogy mi a különbség a landing és a
                                        weboldal között, megnézzük melyiknek mi a célja. Végig vesszük a landing oldalak
                                        fő típusait. Kiszámoljuk hány landing oldalra lehet szükséged. A cikk végére
                                        látni fogod, hogy a landing oldal és a weboldal nem helyettesíti egymást, így
                                        mindkettőre szüksége lesz egy vállalkozásnak hosszú távon.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/mennyibe-kerul-egy-weblap-keszitese.html" style={{padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <Box component="img" src="/blog_cost.webp" className="logo mobile-half" sx={{marginY: '20px'}}/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" >
                                    <h3>
                                        Mennyibe kerül 2024-ben egy weblap készítése Magyarországon?
                                    </h3>
                                    <p>
                                        Gyakran találkozni azzal a kérdéssel, hogy mennyiből lehet elkészíteni egy honlapot.
                                        Nem könnyű egy konkrét számot meghatározni, de sorra tudjuk venni, hogy
                                        milyen tényezőket vegyél figyelembe, mikor szakembert választasz.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/ingyenes-weblap.html" style={{backgroundColor: '#eef7fa', padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <Box component="img" src="/blog_free.webp" className="logo mobile-half" sx={{marginY: '20px'}}/>
                                <Stack direction="column" alignContent="space-between" alignItems="center"  spacing={3}>
                                    <h3>
                                        Teljesen ingyenes weboldal készítés lehetséges? Ingyen honlap készítés programozói tudás
                                        nélkül? Mire használható az ingyenes weblap?
                                    </h3>
                                    <p>
                                        Cikkünkben megmutatjuk, hogy az ingyenesen elkészített honlapoknak valójában milyen
                                        költségei vannak. Szó lesz a legnépszerűbb ingyenes tartalom kezelő rendszerről, a
                                        WordPressről, amely akár programozói tudás nélkül is használható. Megnézzük milyen előnyei
                                        és hátrányai vannak ezeknek az ingyenes sablon webodalaknak, illetve az egyedi készítésű
                                        honlapoknak.
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                        <a href="/blog/weblap-frissites.html" style={{padding: '25px', borderRadius: '45px', textDecoration: 'none'}}>
                            <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                <Box component="img" src="/blog_refresh.webp" className="logo mobile-half" sx={{marginY: '20px'}}/>
                                <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                    <h3>
                                        Miért kell rendszeres honlap karbantartás? Veszélyes is lehet egy nem frissített weblap?
                                        Rendszeres vagy alkalmi frissítésre van szükség?
                                    </h3>
                                    <p>
                                        Ebben a cikkben végignézzük, hogy mikor szorul a holnapod karbantartásra, illetve az alábbi
                                        kérdésekre keressük a választ: Van-e valami szabálya a rendszeres, illetve az alkalmi
                                        frissítésnek? Milyen veszélyeket rejthet egy elavult honlap? Mikor érdemes új weboldalban
                                        gondolkodni? Évente új honlap kell?
                                    </p>
                                    <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                </Stack>
                            </Stack>
                        </a>
                    </Stack>
                </Container>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
        </>
    )
}

export default Blog